const $ = require("jquery");
require('jquery-ui/ui/effects/effect-shake.js');
import Session from './session'
import {LEFT, RIGHT, UP, DOWN, fivesUtil} from './util'
import logMessage from './logger';

var _ = require('underscore');
var playing = false

function render_board() {
    logMessage("Rendering board")
    $('.postgamedisplay').hide();
    var tiles = Session.get("tiles");
    for (var i = 0; i <= 3; i++) {
        for (var j = 0; j <= 3; j++) {
            var t = tiles[i][j];

            if (tiles[i][j] != 0) {
                var block = $("#tile-template").clone()
                $(block).removeAttr('id')
                $(block).attr("data-coords", fivesUtil.coords_string(i, j))
                $(block).html(t)
                $(block).addClass(fivesUtil.tile_class(t));
                // (Here there be magic numbers)
                block = $(block).css({
                    left: 22 + (j * 82),
                    top: 22 + (i * 110)
                });

                $(".board").append(block);
                $(block).show()
            }
        }
    }
    playing = false;
    $('#gamefooter').show();
    // show_claim_score(200, 82)
}

function render_next() {
    var next_tile = Session.get("next_tile");
    $(".next .tile").removeClass("red")
        .removeClass("blue")
        .removeClass("number")
        .removeClass("bonus");
    $(".next .tile").addClass(fivesUtil.tile_class(next_tile));
}

function render_lost(total) {

    playing = false;
    $('#gamefooter').show();

    $( "#gameboard" ).effect( "shake" );

    // Show the score at bottom.
    $('#boardscore').html("Score: " + total)
    $('#gameoverscore').html(total)
    $('#gameoverscore').attr('data-score', total)
    $('.postgamedisplay').show();
    $('#skipserad').hide()

    logMessage("Score - " + total + ", lowerhigh - "+Session.get("highscore"))
    if (total > Session.get("highscore")) {
        $.ajax({
            type: "POST",
            url: "/game/savenewhigh",
            data: { score: total},
            cache: false,
            contentType: "application/x-www-form-urlencoded",
            success: function (result) {
                logMessage("Saved high score.. " +result.scoreid)
                if (result.status == 'success') {
                    show_claim_score(total, result.scoreid)
                }
            }
        })
    }

    // // Close modal
    // $("body").click(function(e) {
    //     if (!$(e.target).closest(".endgame").length) {
    //         overlay.remove();
    //     }
    // });

    // $(window).on("keydown", function(e) {
    //     if (e.keyCode === 13) { // Enter
    //         overlay.remove();
    //     }
    // });
}

const DIRECTIONS = {
    37: { // LEFT
        movement: function(c) {
            return {
                top: c.top,
                left: c.left - 82
            };
        },
        partialMovement: function(c) {
            return {
                top: c.top,
                left: c.left - 60
            };
        },
        coords: function(i, j) {
            return String(i) + String(j - 1);
        },
    },
    39: { // RIGHT
        movement: function(c) {
            return {
                top: c.top,
                left: c.left + 82
            };
        },
        partialMovement: function(c) {
            return {
                top: c.top,
                left: c.left + 60
            };
        },
        coords: function(i, j) {
            return String(i) + String(j + 1);
        },
    },
    38: { // UP
        movement: function(c) {
            return {
                top: c.top - 110,
                left: c.left
            };
        },
        partialMovement: function(c) {
            return {
                top: c.top - 70,
                left: c.left
            };
        },
        coords: function(i, j) {
            return String(i - 1) + String(j);
        },
    },
    40: { // DOWN
        movement: function(c) {
            return {
                top: c.top + 110,
                left: c.left
            };
        },
        partialMovement: function(c) {
            return {
                top: c.top + 70,
                left: c.left
            };
        },
        coords: function(i, j) {
            return String(i + 1) + String(j);
        },
    },
};

function clear_preview(obj) {
    var moved = obj.moved;
    _.each(moved, function(t) {
        var el = $("[data-coords=" + fivesUtil.coords_string(t.i, t.j) + "]");
        var top = 22 + (t.i * 110);
        var left = 22 + (t.j * 82);
        el.stop();
        el.animate({
            top: top,
            left: left,
        }, 200, "easeOutQuart");
    });
}

function preview_move(obj, direction) {
    // var board = obj.board;
    var moved = obj.moved;

    var movement = DIRECTIONS[direction].partialMovement;
    $(".tile").css("zIndex", 10);

    _.each(moved, function(t) {
        var el = $("[data-coords=" + fivesUtil.coords_string(t.i, t.j) + "]");

        var top = 22 + (t.i * 110);
        var left = 22 + (t.j * 82);
        var new_coords = movement({
            top: top,
            left: left
        });

        el.css("zIndex", 100);
        el.stop();
        el.animate({
            top: new_coords.top,
            left: new_coords.left,
            zIndex: 100 // maintain the zIndex at 100
        }, 200, "easeOutQuart");
    });
}

function animate_move(obj, direction) {
    if (! playing) {
        playing = true;
        $('#gamefooter').hide();
    }
    // var board = obj.board;
    var moved = obj.moved;
    logMessage(moved)

    var movement = DIRECTIONS[direction].movement;
    var coords = DIRECTIONS[direction].coords;

    $(".tile").css("zIndex", 10);

    _.each(moved, function(t) {
        logMessage("Moving " + fivesUtil.coords_string(t.i, t.j) + " to " + coords(t.i, t.j))
        var el = $("[data-coords=" + fivesUtil.coords_string(t.i, t.j) + "]");

        var top = 22 + (t.i * 110);
        var left = 22 + (t.j * 82);
        var new_coords = movement({
            top: top,
            left: left
        });

        el.css("zIndex", 100);
        el.stop();
        el.animate({
            top: new_coords.top,
            left: new_coords.left
        }, 200, "easeOutQuart", function() {
            logMessage("Removing item at "+coords(t.i, t.j))
            $("[data-coords=" + coords(t.i, t.j) + "]").remove();
            el.attr("data-coords", coords(t.i, t.j));
            el.removeClass("blue");
            el.removeClass("red");
            el.removeClass("number");
            el.addClass(fivesUtil.tile_class(t.t));
            el.html(t.t);

            // el.effect("bounce", {distance: 30, times: 3});
        });
    });
}

function animate_new_tile(coords, direction) {
    var next_tile = Session.get("next_tile");
    var origin;

    switch (direction) {
        case LEFT:
            origin = function(top, left) {
                return {
                    top: top,
                    left: left + 82
                };
            }
            break;

        case RIGHT:
            origin = function(top, left) {
                return {
                    top: top,
                    left: left - 82
                };
            }
            break;

        case UP:
            origin = function(top, left) {
                return {
                    top: top + 110,
                    left: left
                };
            }
            break;

        case DOWN:
            origin = function(top, left) {
                return {
                    top: top - 110,
                    left: left
                };
            }
            break;
    }

    var block = $("#tile-template").clone();
    $(block).removeAttr('id')
    $(block).attr("data-coords", fivesUtil.coords_string(coords.i, coords.j))
    $(block).html(next_tile)
    $(block).addClass(fivesUtil.tile_class(next_tile));

    var top = 22 + (coords.i * 110);
    var left = 22 + (coords.j * 82);
    var origins = origin(top, left);

    block.css({
        left: origins.left,
        top: origins.top
    });
    $(".board").append(block);
    $(block).show()

    block.animate({
        top: top,
        left: left
    }, 200, "easeOutQuart");
}

function show_claim_score(score, scoreid) {
    var overlay = $("<div/>", {
        class: "overlay"
    });
    var endgame = $('#endgame-template').clone()
    $(endgame).removeAttr('id')
    $(endgame).find('#claimscore-score1').html(score)
    $(endgame).find('input[name="scoreid"]').val(scoreid)
    $(endgame).find('#endgame-cancel').on('click', function() {
        overlay.remove();
    })

    var form = $(endgame).find('form')
    $(form).find('button[name="submits"]').on('click', function(e) {
        e.preventDefault();
        var profileUrl = $(form).find('input[name="profile"]').val()
        var name = $(form).find('input[name="name"]').val()
        if (/^ *$/.test(profileUrl)) {
            //console.log("Got empty profile, returning")
            logMessage("Empty profile url")
        } else if (profileUrl.startsWith('https://twitter') || profileUrl.startsWith('https://www.facebook') || profileUrl.startsWith('https://www.youtube')) {
            logMessage("Empty profile url")
        } else {
            alert("Invalid profile url. Only facebook, twitter and youtube urls allowed!")
            return false
        }

        if (/^ *$/.test(name)) {
            alert("Name can't be empty!!")
            return false
        } else if (/[^A-Z a-z0-9]/.test(name)) {
            alert("Name can only have alphabets and numbers!!")
            return false
        }
        
        // All validations are fine, let's submit.
        $.ajax({   
            type: 'POST',   
            url: '/game/claimscore',   
            data: $(form).serialize(),
            success: function() {
                overlay.remove();
                fivesUtil.populate_high_scores();
            }
         }); 
    })

    overlay.append(endgame);
    endgame.show()
    $("body").append(overlay);
    overlay.fadeIn(200);
}

export default {
    render_board: render_board,
    render_next: render_next,
    render_lost: render_lost,
    animate_move: animate_move,
    animate_new_tile: animate_new_tile,
    preview_move: preview_move,
    clear_preview: clear_preview
};