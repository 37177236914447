import Session from './session'
import $ from 'jquery';
import logMessage from './logger'

var _ = require('underscore');

export const LEFT = 37;
export const RIGHT = 39;
export const UP = 38;
export const DOWN = 40;


// ... is this supposed to be here?
// Session.set("deck", [1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3]);
Session.set("deck", [2, 2, 2, 2, 3, 3, 3, 3, 5, 5, 5, 5]);
Session.set("current_deck", []);

function random_tile() {
    var deck = Session.get("deck");
    var current_deck = Session.get("current_deck");
    var tiles = Session.get("tiles");

    var t;

    // Bonus draw
    var bonus = _.some(_.flatten(tiles), function(t) {return (t >= 80);} )  // Bonus if we have reached 80 in any time
    if (bonus && (Math.random() <= 1/21)) {
        var highest = _.max(_.flatten(tiles));  // Get the biggest tile value
        var size = Math.log(highest / 5) / Math.log(2) - 3; // For 80, size will be 1, for 160, 2 .. etc
        // Get an array of items like [10, 20, 40 ..] etc with number of items as size.
        var bonus_deck = _(size).times(function(n) {
            return 10 * Math.pow(2, n);
        });
        // Pick a random number for bonus_deck and return it
        t = _.sample(bonus_deck);
        return t;
    }

    // Normal draw
    if (_.isEmpty(current_deck)) {
        current_deck = _.shuffle(deck);
    }

    t = _.first(current_deck);
    current_deck = _.rest(current_deck);

    Session.set("deck", deck);
    Session.set("current_deck", current_deck);
    return t;
}

// Helper to compute tile class
function tile_class(tile) {
    if (tile === 2) {
        return "blue";
    }
    else if (tile === 3) {
        return "red";
    }
    else if (tile == 5) {
        return "number";
    }
    else {
        return "bonus";
    }
}

function populate_high_scores() {
    // Populate high score
    $.getJSON( "/cache/highscores.json", function( data ) {
        logMessage("Fetched high scores")
        logMessage(data)
        Session.set("highscore", 0)

        var tablehtml="<table style='font-size:.9em;'>"; var pos=1;
        var lowerHigh = 0
        var numScores = 0
        $.each( data, function( key, val ) {
            //alert("key: "+key+", val: "+val.score );
            if (lowerHigh == 0) {
                lowerHigh = val.score
            } else {
                if (val.score < lowerHigh) {
                    lowerHigh = val.score
                }
            }

            var profileitem=val.name;
            val.profile=val.profile.replace(/^http:\/\/http/, 'http');
            val.profile=val.profile.replace(/^https:\/\/http/, 'http');
            if (val.profile != '' && fivesUtil.ValidURL(val.profile)) {
                profileitem="<a target='_blank' href='"+val.profile+"'>"+val.name+"</a>";
            }
            tablehtml+="<tr><td>#"+pos+"</td><td>"+profileitem+"</td><td>: "+val.score+"</td></tr>";
            pos++;
            numScores++;
        });
        if (numScores < 3) {
            lowerHigh = 0
        }
        Session.set("highscore", lowerHigh)
        logMessage("got lower high - " + Session.get("highscore"))
        tablehtml+="</table>";

        $("#worldrecordsholder").empty()
        $( tablehtml).appendTo("#worldrecordsholder");
    })
}

export var fivesUtil = {
    random_tile: random_tile,
    tile_class: tile_class,
    populate_high_scores: populate_high_scores,
    coords_string: function(i, j) {
        return String(i) + String(j);
    },
    ValidURL: function(str) {
        var pattern = new RegExp('^https?:\\/\\/([\\w\\d\\-]+\\.)+\\w{2,}(\\/.+)?$');
        return pattern.test(str);
    }
};
