

function set(name, value) {
    if (!('fivesSession' in document)) {
        document['fivesSession'] = {}
    }
    document.fivesSession[name] = value
}

function get(name) {
    if (!('fivesSession' in document)) {
        document['fivesSession'] = {}
    }
    if (name in document.fivesSession) {
        return document.fivesSession[name]
    } else {
        return undefined
    }
}

export default {
    get: get,
    set: set
}