import logMessage from './logger'
import '../css/style.scss'
import gameLoader from './main'

// Log message to console
logMessage('A very warm welcome to Expackss!')
gameLoader.startGame()

// Needed for Hot Module Replacement
if(typeof(module.hot) !== 'undefined') { // eslint-disable-line no-undef  
  module.hot.accept() // eslint-disable-line no-undef  
}
