import logMessage from './logger';
const $ = require("jquery");
// require('jquery-ui/ui/effects/effect-shake.js');
import Session from './session';
import fivesGame from './game';
import fivesDisplay from './display';
import { fivesUtil } from './util';
// import html2canvas from 'html2canvas';

import {LEFT, RIGHT, UP, DOWN} from './util'

var _ = require('underscore');

function startGame() {
    initialize();
    logMessage("Starting game")
    logMessage(Session)
    Session.set("deck", [2, 2, 2, 2, 3, 3, 3, 3, 5, 5, 5, 5]);
    Session.set("current_deck", []);
    // GAnalytics.pageview();

    // Start new game if none exists
    if (!Session.get("tiles")) {
        fivesGame.new_game();
    } else {
        fivesDisplay.render_board();
        fivesDisplay.render_next();
    }

    // Handle key presses
    var lazy_move = _.throttle(fivesGame.move, 250, true);
    $(window).on("keydown", function(e) {
        if (e.keyCode === LEFT ||
            e.keyCode === RIGHT ||
            e.keyCode === UP ||
            e.keyCode === DOWN) {
            e.preventDefault();
            if (e.shiftKey) {
                fivesGame.preview_move(e);
            } else {
                if (!fivesGame.is_preview()) {
                    logMessage("Got move")
                    lazy_move(e);
                    // fivesGame.move(e)
                }
            }
        }
    });

    $(window).on("keyup", function(e) {
        if (e.keyCode === LEFT ||
            e.keyCode === RIGHT ||
            e.keyCode === UP ||
            e.keyCode === DOWN) {
            e.preventDefault();
            fivesGame.clear_preview();
        }
    });

    // Handle "new game"
    $("#new-game").click(fivesGame.new_game);

    // Handle music controls
    var method = "play";
    $("#music-control").click(function(e) {
        e.preventDefault();
        if (method === "play") {
            $("#music-audio").get(0)["play"]();
            $(this).html("Pause");
            method = "pause";
        } else {
            $("#music-audio").get(0)["pause"]();
            $(this).html("Play");
            method = "play";
        }
    });

    fivesUtil.populate_high_scores()

    // $( "#gameboard" ).effect( "shake" );
}

function initialize() {
    
}




export default {
    startGame: startGame
}